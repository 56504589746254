import NoContent from '@/components/NoContent/NoContent.vue'
import * as Api from '@/api/course/course'

export default {
  components: {
    NoContent
  },
  props: {
    isComment: {
      type: Boolean,
      default: false
    },
    courseId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      rate: 0,
      colors: ['#29D087', '#29D087', '#29D087'],
      textareaData: '',
      noContentData: {
        pic: 'evaluation_pic',
        tip: '还没有人评价哦'
      },
      commentList: [],
      pagination: {},
      isBuy: false,
      currentCourseId: ''
    }
  },
  mounted () {
    this.currentCourseId = this.courseId || this.$route.params.id
    if (this.$route.path.indexOf('user') > 0) {
      this.isBuy = true
    }
    console.log((this.$store.state.user.authorization.access_token) && (!this.isComment) && this.isBuy)
    this.getComments()
  },
  methods: {
    getComments (page = 1) {
      let self = this
      let courseId = this.courseId
      let params = {
        page: page
      }
      Api.courseCommentApi(courseId, params, (res, meta) => {
        self.commentList = res
        self.pagination = meta
      })
    },

    subComment () {
      let courseId = this.currentCourseId
      let params = {
        course_id: courseId,
        star: this.rate,
        content: this.textareaData
      }
      Api.subCourseCommentApi(params, () => {
        this.isComment = true
        this.$message.success('评论提交成功')
      })
    },
  }
}
