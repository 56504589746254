<template>
  <div class="comment">
    <div class="course-comment" v-if="commentList.length>0">
      <template v-if="isBuy">
        <div class="use-comment" v-if="($store.state.user.authorization.access_token)&&(!isComment)"  >
          <div class="total-rate">
            <span class="title">课程评分</span>
            <el-rate :colors="colors" v-model="rate"></el-rate>
          </div>
          <div class="write-box">
      <textarea
          class="write-con"
          placeholder="说说你的课程感受"
          style="width: 100%;
height: 140px"
          v-model="textareaData"
      >
      </textarea>
            <div class="action">
              <span class="account">{{textareaData.length}}/300</span>
              <div class="button" @click="subComment">
                发布
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="comment-list">
          <div class="comment-item" v-for="(item,index) in commentList" :key="index">
            <div class="avatar">
              <img :src="item.user.avatar" alt="">
            </div>
            <div class="comment-detail">
              <div class="username">{{item.user.nickname}}</div>
              <div class="second-line">{{item.learn_progress}} {{item.created_at}}</div>
              <div class="comment-content">{{item.content}}</div>
              <div class="comment-reply" v-if="item.reply!==''">
                <div class="triangle"></div>
                <span class="reply-con">{{item.reply}}</span></div>
            </div>
            <div class="rate">
              <el-rate disabled :colors="colors" v-model="item.star"></el-rate>
            </div>
          </div>

        </div>
    </div>
    <div class="course-comment" v-else>
      <div class="use-comment" v-if="($store.state.user.authorization.access_token)&&(!isComment)&&isBuy"  >
        <div class="total-rate">
          <span class="title">课程评分</span>
          <el-rate :colors="colors" v-model="rate"></el-rate>
        </div>
        <div class="write-box">
      <textarea
          class="write-con"
          placeholder="说说你的课程感受"
          style="width: 100%;
height: 140px"
          v-model="textareaData"
      >
      </textarea>
          <div class="action">
            <span class="account">{{textareaData.length}}/300</span>
            <div class="button" @click="subComment">
              发布
            </div>
          </div>
        </div>
      </div>
      <NoContent :data="noContentData" :hh="($store.state.user.authorization.access_token)&&(!isComment)&&isBuy"></NoContent>
    </div>
    <el-pagination
        @size-change="getComments"
        @current-change="getComments"
        v-if="pagination"
        :hide-on-single-page="true"
        background
        layout="prev, pager, next"
        :page-size="pagination.per_page"
        :total="pagination.total">
    </el-pagination>
  </div>
</template>

<script>
  import CommentJs from './Comment.js'

  export default CommentJs
</script>

<style lang="less" scoped>
  /*@import '../../assets/style/lib-base';*/

  .course-comment {
    .total-rate {
      display: flex;
      align-items: center;

      & > .title {
        font-size: @fontSizeTitle;
        color: #36404A;
        font-weight: bold;
        margin-right: 11px;
      }
    }

    .write-box {
      margin-top: 20px;
      width: 100%;

      & > .write-con {
        box-sizing: border-box;
        border: 1px solid #d0d0d0;
        padding: 20px;
        font-size: @fontSizeSubTitle;
        outline: none;
      }

      .write-box::placeholder {
        color: #999;
      }

      & > .action {
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 26px;

        & > .account {
          font-size: @fontSizeSubTitle;
          color: #999;
        }

        & > .button {
          cursor: pointer;
          width: 100px;
          height: 40px;
          background: @papaGreen;
          border-radius: 3px;
          text-align: center;
          line-height: 40px;
          color: #fff;
        }
      }
    }

    & > .comment-list {
      margin-top: 20px;

      & > .comment-item {
        display: flex;
        border-bottom: 1px solid #e6e6e6;
        padding: 20px 0;

        & > .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        & > .comment-detail {
          display: flex;
          flex-direction: column;
          padding-left: 10px;
          text-align: left;

          & > .username {
            font-size: @fontSizeSpTitle;
            color: #333;
            font-weight: bold;
          }

          & > .second-line {
            font-size: @fontSizeSubTitle;
            color: #a4a9b2;
            margin: 5px 0 12px;
          }

          & > .comment-content {
            font-size: @fontSizeSubTitle;
            color: #333;
          }

          .triangle {
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 10px solid #f5f7fa;
            position: absolute;
            top: -10px;
          }

          & > .comment-reply {
            position: relative;
            background: #f5f7fa;
            padding: 30px;
            border-radius: 5px;
            margin-top: 10px;

            & > .reply-con {
              font-size: @fontSizeSubTitle;
              color: #666;
            }
          }
        }

        & > .rate {
          position: absolute;
          right: 0;
        }
      }
    }

  }
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
</style>
