<template>
  <div class="class-schedule">
    <div v-if="classSchedule.length > 0">
      <el-collapse v-model="activeNames">
        <template v-for="item in classSchedule">
          <el-collapse-item :title="item.title" :name="item.id" :key="item.id">
            <div class="itemBox">
              <div
                class="course-item"
                v-for="child in item.children"
                :key="child.id"
              >
                <template v-if="child.course_mode === 4">
                  <div class="can_enter" @click="showVideo(child)">
                    <div class="icon">
                      <i
                        v-if="child.course_mode === 4"
                        class="iconfont icon-papa-vedio cursor_pointer"
                      ></i>
                    </div>
                    <div class="course-desc cursor_pointer">
                      <div class="first-line">
                        <div class="course-mode">[ 抢先听 ]</div>
                        <div>
                          {{ child.title }}
                        </div>
                      </div>
                      <div class="second-line">
                        视频时长 {{ formatSecond(child.video_duration) }} 分钟
                      </div>
                    </div>
                  </div>
                  <div
                    class="button_jump-to-exam"
                    v-if="child.exercise_url"
                    @click="openPage(child.exercise_url)"
                  >
                    <i class="iconfont icon-papa-practice">随堂练习 </i>
                  </div>
                </template>
                <template v-else>
                  <div class="left">
                    <div class="icon">
                      <i
                        v-if="child.course_mode === 1"
                        class="iconfont icon-papa-live"
                      ></i>
                      <i
                        v-if="child.course_mode === 2"
                        class="iconfont icon-papa-recording"
                      ></i>
                      <i
                        v-if="child.course_mode === 3"
                        class="iconfont icon-papa-vedio"
                      ></i>
                    </div>
                    <div class="course-desc">
                      <div class="first-line">
                        <div class="course-mode" v-if="child.course_mode === 1">
                          [ 直播 ]
                        </div>
                        <div class="course-mode" v-if="child.course_mode === 2">
                          [ 录播 ]
                        </div>
                        <div class="course-mode" v-if="child.course_mode === 3">
                          [ 视频 ]
                        </div>
                        <div>
                          {{ child.title }}
                        </div>
                      </div>
                      <div class="second-line" v-if="child.course_mode !== 1">
                        视频时长 {{ formatSecond(child.video_duration) }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="button_jump-to-exam"
                    v-if="child.exercise_url"
                    @click="openPage(child.exercise_url)"
                  >
                    <i class="iconfont icon-papa-practice">随堂练习 </i>
                  </div>
                </template>
              </div>
            </div>
          </el-collapse-item>
        </template>
      </el-collapse>
    </div>
    <NoContent v-else :data="noContentData"></NoContent>
  </div>
</template>

<script>
import ClassScheduleJs from "./ClassSchedule.js";

export default ClassScheduleJs;
</script>

<style lang="less" scoped>
/*@import '../../assets/style/lib-base.less';*/

.el-collapse {
  border-top: none;
}

.class-schedule {
  .itemBox {
    :last-child {
      border: none;
    }
  }

  .course-item {
    display: flex;
    margin: 0 40px;
    border-bottom: 1px solid #e6e6e6;
    //padding: 10px 0;
    justify-content: space-between;
    align-items: center;

    .can_enter,
    .left {
      width: 100%;
      display: flex;
      padding: 20px 0;

      .cursor_pointer {
        cursor: pointer;
      }
    }

    .icon {
      font-size: 25px;
      margin-right: 20px;
    }
    a,
    div {
      display: flex;

      & > .icon {
        font-size: 25px;
        margin-right: 20px;
      }
    }

    .course-desc {
      font-size: @fontSizeCon;
      color: #333333;
      font-weight: 400;
      flex-direction: column;

      .first-line {
        display: flex;

        .course-mode {
        }
      }
    }

    .button_jump-to-exam {
      cursor: pointer;
      width: 116px;
      height: 32px;
      border-radius: 6px 0px 6px 6px;
      background: @papaGreen;
      color: #fff;
      display: flex;
      justify-content: center;
      text-align: center;
      line-height: 32px;
      font-size: 14px;
    }
  }
}
</style>
